import {requestMicrophonePermission$} from '../../../utils/browser.utils';
import {catchError, debounceTime, share, switchMap, take, takeUntil} from 'rxjs/operators';
import {merge, Observable, timer} from 'rxjs';
import {webSocket, WebSocketSubject} from 'rxjs/webSocket';
import {WebSocketMessage} from 'rxjs/internal/observable/dom/WebSocketSubject';
import {SpeechToText, SpeechToTextOptions} from './speech-to-text';
import {AudioStreamResponse} from '@services/buddy/dist/gen/buddy_pb';
import {getQueryString} from '../../../@core/api.service';
import {extractErrorMessage} from '../../../utils/error.utils';
import {NgZone} from '@angular/core';
import {JWTService} from '../../../@core/jwt.service';
import {JWTInterceptor} from '../../../@core/injectables/jwt-interceptor';

export const DEEPGRAM_STT_DEFAULT_OPTIONS: SpeechToTextOptions = {
    continuous: true,
    replacePunctuation: false,
};

export const DEEPGRAM_STT_TIMEOUT_SECS = {
    default: 3,
    continuous: 10,
};

export class DeepgramSpeechToText extends SpeechToText<AudioStreamResponse> {
    private _mediaRecorder: MediaRecorder;
    private _webSocket$: WebSocketSubject<any>;
    private _result: string;

    get defaultOptions() {
        return DEEPGRAM_STT_DEFAULT_OPTIONS;
    }

    get isProcessing() {
        return !this.isListening && !this._webSocket$?.closed;
    }

    constructor(protected ngZone: NgZone, options: SpeechToTextOptions, private jwt: JWTService) {
        super(ngZone, options);
    }

    protected _recognition$ = requestMicrophonePermission$().pipe(
        switchMap(() => new Observable<string>(subscriber => {
            navigator.mediaDevices.getUserMedia({audio: true}).then(stream => {
                if ('MediaRecorder' in window) {
                    this._mediaRecorder = new MediaRecorder(stream);
                }

                if (!this._mediaRecorder) {
                    this._propagateError('MediaRecorder is not supported by your browser', subscriber);
                    return;
                }

                this._mediaRecorder.onstart = () => this.zone.run(() => {
                    this._initiateRecordingTimeout(
                        this._options.timeoutSecs ??
                        (this._options.continuous ? DEEPGRAM_STT_TIMEOUT_SECS.continuous : DEEPGRAM_STT_TIMEOUT_SECS.default)
                    );
                    this._result = '';
                    this._webSocket$?.complete();
                    this._webSocket$ = this._createWebSocketConnection();
                    this._webSocket$.pipe(catchError(err => {
                        if (err.error === 'FAILED_TO_VERIFY_TOKEN') {
                            return JWTInterceptor.handleAuthError(this.jwt, err, () => this._createWebSocketConnection());
                        }
                        throw err;
                    })).subscribe({
                        next: msg => msg.transcription && msg.isFinal && subscriber.next(this._processResult(msg)),
                        error: err => {
                            this._propagateError(extractErrorMessage(err, null, 'WebSocket connection error'), subscriber);
                            this._isListening = false;
                        },
                        complete: () => subscriber.complete(),
                    });
                    this._isListening = true;
                });
                this._mediaRecorder.onstop = () => this.zone.run(() => {
                    this._isListening = false;
                    if (this._webSocket$ && !this._webSocket$.closed) {
                        merge(
                            timer(500).pipe(takeUntil(this._webSocket$)),
                            this._webSocket$.pipe(debounceTime(250)),
                        ).pipe(take(1)).subscribe(() => {
                            this._webSocket$.complete();
                        });
                    } else {
                        subscriber.complete();
                    }
                });
                this._mediaRecorder.ondataavailable = event => event.data && this._webSocket$.next(event.data);

                this._mediaRecorder.start(1000);

                subscriber.add(() => {
                    this._mediaRecorder.stop();
                    this._webSocket$?.complete();
                });
            });
        })),
        share(),
    );

    stop() {
        if (this._mediaRecorder) {
            this._mediaRecorder.stream.getTracks().forEach(track => track.stop());
            this._mediaRecorder.stop();
        } else {
            this._subscribers.forEach(x => x.complete());
        }
    }

    private _createWebSocketConnection() {
        return webSocket({
            url: this._getBuddyWSUrl(),
            binaryType: 'blob',
            serializer: e => e as WebSocketMessage,
            deserializer: e => {
                const msg = JSON.parse(e.data);
                if (msg.error) {
                    throw msg;
                }
                return msg;
            },
        });
    }

    protected _parseString(res: AudioStreamResponse) {
        this._result = [this._result, res.transcription].filter(x => x).join(' ');
        return this._result;
    }

    private _getBuddyWSUrl = () => {
        const wsScheme = window.location.protocol === 'https:' ? 'wss://' : 'ws://';
        const hostName = window.location.hostname;
        if (hostName === 'arsana.local') return `ws://localhost:8081?${getQueryString({token: localStorage.getItem('jwt-token')})}`;
        return `${wsScheme}${hostName}/services.buddy.v2/ws?${getQueryString({token: localStorage.getItem('jwt-token')})}`;
    };
}
