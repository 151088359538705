import {Observable} from 'rxjs';
import {share, switchMap} from 'rxjs/operators';
import {requestMicrophonePermission$} from '../../../utils/browser.utils';
import {SpeechToText, SpeechToTextOptions} from './speech-to-text';

declare const webkitSpeechRecognition: any;

export interface SpeechToTextSpeechRecognitionOptions extends SpeechToTextOptions {
    interimResults?: boolean;
    maxAlternatives?: number;
    lang?: string;
    autoPeriod?: boolean;
}

export const SPEECH_RECOGNITION_STT_DEFAULT_OPTIONS: SpeechToTextSpeechRecognitionOptions = {
    continuous: true,
    interimResults: true,
    maxAlternatives: 1,
    lang: 'en-US',
    autoPeriod: false,
    replacePunctuation: true,
};

export class SpeechRecognitionSpeechToText extends SpeechToText<SpeechRecognitionEvent> {
    private _recognition: SpeechRecognition;
    protected _options: SpeechToTextSpeechRecognitionOptions;
    protected _recognition$ = requestMicrophonePermission$().pipe(
        switchMap(() => new Observable<string>(subscriber => {
            if ('SpeechRecognition' in window) {
                this._recognition = new SpeechRecognition();
            } else if ('webkitSpeechRecognition' in window) {
                this._recognition = new webkitSpeechRecognition();
            }

            if (!this._recognition) {
                this._propagateError('Speech recognition is not supported by your browser', subscriber);
                return;
            }

            this._recognition.continuous = this._options.continuous;
            this._recognition.interimResults = this._options.interimResults;
            this._recognition.lang = this._options.lang;
            this._recognition.maxAlternatives = this._options.maxAlternatives;

            this._recognition.onstart = () => this.zone.run(() => this._isListening = true);
            this._recognition.onend = () => this.zone.run(() => {
                this._isListening = false;
                subscriber.complete();
            });
            this._recognition.onresult = e => this.zone.run(() => subscriber.next(this._processResult(e)));
            this._recognition.onerror = e => this.zone.run(() => subscriber.error(e.error));

            this._recognition.start();

            subscriber.add(() => {
                this._recognition.stop();
            });
        })),
        share(),
    );

    get defaultOptions() {
        return SPEECH_RECOGNITION_STT_DEFAULT_OPTIONS;
    }

    stop() {
        if (this._recognition) {
            this._recognition.stop();
        } else {
            this._subscribers.forEach(x => x.complete());
        }
    }

    protected _parseString(res: SpeechRecognitionEvent) {
        return Array.from(res.results)
            .filter(x => x[0].transcript.trim())
            .map(x => {
                let transcript = x[0].transcript.trim();
                if (this._options.continuous && this._options.autoPeriod && x.isFinal) {
                    transcript += '.';
                }
                return transcript;
            })
            .join(' ');
    }
}
