import {
    Component,
    EventEmitter,
    forwardRef,
    HostBinding,
    HostListener,
    Input,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import {AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator} from '@angular/forms';
import {ForwardNgSelectRefs} from './forward-ng-select-refs';
import {OptionsAPIOptions} from '../../../../@core/forms/form-field-select';
import {debounceTime, distinctUntilChanged, filter, finalize, map, switchMap, take, takeUntil} from 'rxjs/operators';
import {EMPTY, Observable, of, Subject} from 'rxjs';
import {NgSelectComponent} from '@ng-select/ng-select';
import {CompareWithFn} from '@ng-select/ng-select/lib/ng-select.component';
import {SimpleDatePipe} from '../../../../@theme/pipes/simple-date.pipe';
import {catchAndPropagateError} from 'src/app/utils/observable.utils';
import {DatepickerSelectComponent} from '../datepicker/datepicker-select.component';
import {ToggleComponent} from '../../../../@theme/components/toggle/toggle.component';
import {SpeechToTextService} from '../../../@portal-shared/speech-to-text/speech-to-text.service';
import {SpeechToText} from '../../../@portal-shared/speech-to-text/speech-to-text';

/* TODO this should be replaced with HtmlInputType when the relevant change is merged (from #3658 !1368) */
export type AdvancedInputType =
    'input'
    | 'text'
    | 'textarea'
    | 'select'
    | 'checkbox'
    | 'date'
    | 'datetime'
    | 'subform'
    | 'snomed-search';

export type AdvancedInputVariant = 'form-group' | 'editor-field';

@Component({
    selector: 'app-advanced-input',
    providers: [
        {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => AdvancedInputComponent), multi: true},
        {provide: NG_VALIDATORS, useExisting: forwardRef(() => AdvancedInputComponent), multi: true},
    ],
    templateUrl: './advanced-input.component.html',
    styleUrls: ['./advanced-input.component.scss'],
})
export class AdvancedInputComponent extends ForwardNgSelectRefs implements ControlValueAccessor, OnInit, Validator {
    _model: any;
    private _prevModel: any;

    @Input() limit: number;
    @Input() charCount = false;
    @Input() hint: string;
    @Input() rows: number;
    @Input('label') _label: string;
    @Input('placeholder') _placeholder: string;
    @Input() type: AdvancedInputType = 'input'; // TODO AdvancedInputType should be replaced with HtmlInputType when the relevant change is merged (from #3658 !1368)
    @Input() inputType: 'text' | 'number' | 'email' | 'password' | 'url';
    @Input() read = false;
    @Input() readView: 'standard' | 'simplified' = 'standard';
    @Input() variant: AdvancedInputVariant;
    @Input() min: number;
    @Input() max: number;
    @Input() multi = false;
    @Input() icon: string;
    @Input() selectItems: any[];
    @Input() preloadedSelectItems: any[];
    @Input() clearable = true;
    @Input() textClearable: boolean;
    @HostBinding('class.compact')
    @Input() compact: boolean;
    @Input() wide = false;
    @Input() narrow = false;
    @HostBinding('class.is-invalid')
    @Input() invalid: boolean;
    @Input() error: string | string[];
    @Input() bindLabel = 'name';
    @Input() bindValue = 'id';
    @Input() bindSameAs = 'same_as';
    @Input() bindCode: string;
    @Input() groupBy = null;
    @Input() selectableGroup: boolean;
    @Input() @HostBinding('class.disabled') disabled = false;
    @Input() modelOptions: {
        name?: string;
        standalone?: boolean;
        updateOn?: string;
    };
    @Input() asyncOptionsAPI: OptionsAPIOptions;
    @Input() asyncOptionsPreloadQuery: any[];
    @Input() numberOfResults = 50;
    @Input() required: boolean;
    @Input() align: 'left' | 'right' | 'center';
    @Input() suffix: string;
    @Input() compareWith: CompareWithFn;
    @Input() searchTermMinLength = 0;
    @Input() multiSelectSeparator = ', ';
    @Input() virtualScroll = false;
    @Input() searchFn: (a, b) => boolean;
    @Input() hasSpeech = false;
    @Input() term: string;
    @Input() termPriority: boolean = true;
    @ViewChild('input') input;
    @ViewChild(NgSelectComponent, {static: false}) ngSelectComponent: NgSelectComponent;
    @HostBinding('class.focus') focus = false;
    @HostBinding('class.simplified') get _isSimplified() {
        return this.readView === 'simplified';
    }
    @HostBinding('class.form-control') get _isEditorFieldVariant() {
        return this.variant === 'editor-field';
    }
    @HostBinding('class.form-group') get _isFormGroupVariant() {
        return this.variant === 'form-group';
    }
    @HostBinding('class.listening') get isListening() {
        return this.speechToText?.isListening;
    }

    @Output() change = new EventEmitter(); // for emitting original data of parsed options
    @Output() close = new EventEmitter();
    @Output() search = new EventEmitter<{term: string; items: any[]}>();
    @Output() clear = new EventEmitter<void>();

    typeahead: Subject<string>;
    termSearch: Subject<string>;
    searchTerm: string;
    textSelection: {start: number; end: number};
    speechToText: SpeechToText;
    hasDictationPermission = false;

    @HostListener('click') focusElement() {
        if (!this.input) return;
        switch (this.type) {
            case 'select':
                (this.input as NgSelectComponent).open();
                break;
            case 'date':
            case 'datetime':
                (this.input as DatepickerSelectComponent).open();
                break;
            case 'checkbox':
                (this.input as ToggleComponent).toggle();
                break;
            default:
                this.input.nativeElement?.focus();
        }
    }

    get label(): string {
        return this._label ? this._label + (!this.read && this.required ? ' *' : '') : null;
    }

    get placeholder(): string {
        return this._placeholder || this.compact && this.label || null;
    }

    get caption(): string {
        if (this.type === 'select') {
            if (this.multi) {
                const vals = this.selectItems && this._model && this.selectItems.filter(x => this._model.includes((this.bindValue ? x[this.bindValue] : x)));
                return vals && vals.map(x => this.bindLabel ? x[this.bindLabel] : x).join(this.multiSelectSeparator);
            }

            const val = this.selectItems && this.selectItems.find(x => (this.bindValue ? x[this.bindValue] : x) === this._model);
            return val && this.bindLabel ? val[this.bindLabel] : val;
        } else if (this.type === 'date') {
            return SimpleDatePipe.transform(this._model, false);
        }

        return this._model;
    }

    constructor(private speechToTextService: SpeechToTextService) {
        super();
    }

    ngOnInit() {
        if (this.asyncOptionsAPI && this.asyncOptionsPreloadQuery) {
            this.asyncOptionsAPI.viewSet[this.asyncOptionsAPI.listMethod || 'list'](this.asyncOptionsPreloadQuery).subscribe(preloadedSelectItems => {
                this.preloadedSelectItems = preloadedSelectItems;
            });
        } else if (this.preloadedSelectItems) {
            this.selectItems = this.preloadedSelectItems;
        }

        if (this.type === 'select' && this.asyncOptionsAPI) {
            this.typeahead = new Subject<string>();
            this.typeahead.pipe(
                takeUntil(this.destroy$),
                filter(term => term && term.length >= this.searchTermMinLength || !!this.preloadedSelectItems?.length),
                distinctUntilChanged(),
                debounceTime(200),
                switchMap(term => this.loadOptions(term).pipe(map(items => [items, term]), catchAndPropagateError(() => EMPTY))),
            ).subscribe(([items, term]) => {
                this.selectItems = term && term.length >= this.searchTermMinLength || !this.preloadedSelectItems.length ? items : this.preloadedSelectItems;
                this.searchTerm = term;
                this.search.emit({term, items});
                /**
                 * FIXME: this is a workaround to update the top scroll position for ng-select options for keyboard navigation
                 *  when the options are updated (async options) there is a custom option template
                 *  https://github.com/ng-select/ng-select/issues/1940
                 */
                setTimeout(() => this.ngSelectComponent?.dropdownPanel?.['_updateItems'](true));
            });
        }
        if (this.inputType === 'number' && this.min === undefined) {
            this.min = 0;
        }

        if (!this.read && this.hasSpeech || this.type === 'textarea') {
            this.hasDictationPermission = this.speechToTextService.hasDictationPermission();
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.error && !changes.invalid) {
            this.invalid = !!changes.error.currentValue;
        }
        if (changes.term?.currentValue) {
            if (!this.termSearch) {
                this.termSearch = new Subject<string>();
                this.termSearch.pipe(takeUntil(this.destroy$), debounceTime(400)).subscribe(_ => {
                    if (this.asyncOptionsAPI) {
                        this.loadOptions(this.term).pipe(take(1)).subscribe(options => {
                            this.selectItems = this.term && this.term.length >= this.searchTermMinLength || !this.preloadedSelectItems.length ? options : this.preloadedSelectItems;
                            if (this.selectItems.length !== 1) {
                                this.input.searchTerm = this.term;
                                if (this.termPriority) (this.input as NgSelectComponent).open();
                            } else if (this.selectItems.length === 1) {
                                const item = this.selectItems[0][this.bindValue];
                                this.onNgModelChange(this.multi ? [item] : item);
                            };
                        });
                    }
                });
            }
            this.termSearch.next(this.term);
        }
    }

    writeValue(value: any) {
        if (value !== undefined) {
            let isChanged = false;
            const temp = [];
            if (value) {
                const processVal = val => {
                    const out = this._getValue(val);
                    temp.push(val);
                    if (out !== val) {
                        isChanged = true;
                    }
                    return out;
                };

                if (Array.isArray(value)) {
                    value = value.map(val => processVal(val));
                } else {
                    value = processVal(value);
                }

                this._model = value;
                this._prevModel = value;

                if (isChanged) setTimeout(() => this.propagateChange(value));

                if (this.asyncOptionsAPI) {
                    (Array.isArray(value) ? value : [value]).forEach(val => {
                        if (!this.selectItems || this.selectItems.every(x => this._getValue(x) !== val)) {
                            const res = temp.find(x => this._getValue(x) === val);
                            const id = ['number', 'string'].includes(typeof val) ? val : val.id;
                            (res && !['number', 'string'].includes(typeof res) ?
                                of(res) :
                                this.asyncOptionsAPI.retrieveMethod ?
                                    this.asyncOptionsAPI.viewSet[this.asyncOptionsAPI.retrieveMethod]({code: id}) :
                                    this.asyncOptionsAPI.viewSet.retrieve(id)).subscribe(res => {
                                this.selectItems = [...(this.selectItems || []), this._processItem(res)];
                            });
                        }
                    });
                }
            }

            this._model = value;
            this._prevModel = value;
        }
    }

    propagateChange = (_: any) => {
    };

    propagateTouch = (_: any) => {
    };

    registerOnChange(fn) {
        this.propagateChange = fn;
    }

    registerOnTouched(fn) {
        this.propagateTouch = fn;
    }

    onKeyDown(event: KeyboardEvent) {
        this.speechToTextService.stopListening();

        if (this.limit && this._model && this._model.length >= this.limit) {
            const selection = document.getSelection && document.getSelection();
            if (
                selection?.isCollapsed &&
                !['Backspace', 'Delete', 'ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'Home', 'End', 'Tab', 'F12'].includes(event.key) &&
                (!event.ctrlKey && !event.metaKey || event.key === 'v')
            ) {
                event.preventDefault();
            }
        }
    }

    onNgModelChange(value) {
        if (this.limit && ['textarea', 'input'].includes(this.type) && value.length > this.limit) {
            value = value.substring(0, this.limit);
            this.input.nativeElement.innerHTML = value;
        }

        this._model = value;

        if ((!this.modelOptions || !['blur', 'submit'].includes(this.modelOptions.updateOn)) && this._prevModel !== this._model) this._processChange();
    }

    onBlur() {
        this.focus = null;
        if (this.modelOptions && this.modelOptions.updateOn === 'blur' && this._prevModel !== this._model) this._processChange();
        this._clearTerm();
        this.propagateTouch(true);
    }

    onClose(event) {
        this._clearTerm();
        this.close.emit(event);
    }

    onSearch(search: {term: string; items: any[]}) {
        if (this.typeahead) return;

        this.searchTerm = search.term;
        this.search.emit(search);
    }

    onPaste(_) {
        if (this._model) this.onNgModelChange((this._model as string).trim());
    }

    loadOptions(searchTerm: string): Observable<any> {
        this.cancelRequest$.next();

        const requestParams: any = {search: searchTerm};
        if (this.numberOfResults) {
            Object.assign(requestParams, {page_size: this.numberOfResults, page: 1});
        }
        if (this.asyncOptionsAPI.filtersFunction) {
            // TODO: implement this
            const filters = this.asyncOptionsAPI.filtersFunction(null);
            if (filters) Object.assign(requestParams, filters);
        }
        return this.asyncOptionsAPI.viewSet[this.asyncOptionsAPI.listMethod || 'list'](requestParams).pipe(takeUntil(this.cancelRequest$), map(res => (res ? res['results'] || res : []).map(x => this._processItem(x))));
    }

    stopSelectPropagation(event) {
        if (event.composedPath().some(el => el.tagName === 'NG-DROPDOWN-PANEL')) event.stopPropagation();
    }

    validate(control: AbstractControl): ValidationErrors {
        if (!this.required && !this.limit) return null;

        const e: any = {};
        if (this.required && !control.value) e.required = true;
        if (this.limit && control.value?.length > this.limit) e.overCharLimit = true;

        return Object.keys(e).length ? e : null;
    }

    startSpeechToText(event: MouseEvent) {
        event.preventDefault();
        event.stopPropagation();
        const keepText = {
            start: (this._model?.substring(0, this.textSelection?.start ?? this._model.length) || '').trim(),
            end: (this.textSelection && this._model?.substring(this.textSelection.end, this._model.length) || '').trim(),
        };
        this.speechToText = this.speechToTextService.startListening$();
        this.speechToText.pipe(
            takeUntil(this.destroy$),
            finalize(() => this.speechToText = null),
        ).subscribe(value => {
            this.onNgModelChange([keepText.start, value, keepText.end].filter(x => x).join(' '));
        });
    }

    stopSpeechToText(event: MouseEvent) {
        event.preventDefault();
        event.stopPropagation();
        this.speechToText.stop();
    }

    saveSelection() {
        if (this.focus) {
            this.textSelection = null;
            const aO = window.getSelection().anchorOffset;
            const fO = window.getSelection().focusOffset;
            let start = fO;
            let end = aO;
            if (aO < fO) {
                start = aO;
                end = fO;
            }
            this.textSelection = {start, end};
        } else {
            this.textSelection = null;
        }
    }

    private _getValue(value: any) {
        if (typeof value === 'number' || !value) return value;

        const selectItem = value.id && this.selectItems?.length && this.selectItems.find(x => x.id === value.id);

        return (this.asyncOptionsAPI?.valueFunction && this.asyncOptionsAPI.valueFunction(value)) ?? (this.bindValue && value[this.bindValue]) ?? selectItem ?? value;
    }

    private _processItem(item: any) {
        if (this.asyncOptionsAPI.processSelectItem) return this.asyncOptionsAPI.processSelectItem(item);

        if (this.asyncOptionsAPI.nameFunction) {
            return {
                ...item,
                name: this.asyncOptionsAPI.nameFunction(item),
            };
        }

        return item;
    }

    private _processChange() {
        this.invalid = false;
        this.error = null;
        this._prevModel = this._model;
        this.propagateChange(this._model);

        if (this.selectItems) {
            if (!this._model && typeof this._model !== 'number') return this.change.emit(this._model);

            const x = this.selectItems.find(x => this._getValue(x) === this._model);
            if (x) this.change.emit(x.data || x);
        }
    }

    private _clearTerm() {
        if (this.termSearch) {
            this.termSearch = null;
        }
    }
}
