import {Injectable, NgZone} from '@angular/core';
import {SpeechRecognitionSpeechToText} from './speech-recognition-speech-to-text';
import {PARAMETERS} from '../../../@core/parameters';
import {DeepgramSpeechToText} from './deepgram-speech-to-text';
import {SpeechToText, SpeechToTextOptions} from './speech-to-text';
import {JWTService} from '../../../@core/jwt.service';
import {NgxPermissionsService} from 'ngx-permissions';

@Injectable({
    providedIn: 'root',
})
export class SpeechToTextService {
    private _active: SpeechToText;

    get isAvailable() {
        return PARAMETERS.use_deepgram || 'webkitSpeechRecognition' in window || 'SpeechRecognition' in window;
    }

    constructor(private zone: NgZone,
                private jwt: JWTService,
                private permissionService: NgxPermissionsService) {
    }

    startListening$(options?: SpeechToTextOptions): SpeechToText {
        this.stopListening();
        if (PARAMETERS.use_deepgram) {
            this._active = new DeepgramSpeechToText(this.zone, options, this.jwt);
        } else {
            this._active = new SpeechRecognitionSpeechToText(this.zone, options);
        }
        return this._active;
    }

    stopListening() {
        this._active?.stop();
    }

    hasDictationPermission() {
        return !!this.permissionService.getPermission('PERMISSION_USE_DICTATION');
    }
}
