import {Observable} from 'rxjs';
import {ToastService} from '../@core/toast.service';

export const requestMicrophonePermission$ = () => new Observable(observer => {
    navigator.mediaDevices.getUserMedia({audio: true})
        .then(stream => {
            // If permission is granted, stop the stream immediately
            stream.getTracks().forEach(track => track.stop());
            observer.next();
            observer.complete();
        })
        .catch(() => {
            const title = 'Microphone permission denied';
            ToastService.warning('Please enable our site to use your microphone in the browser and reload the page', title);
            observer.error(title);
        });
});
